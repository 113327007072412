import { Client } from 'contensis-delivery-api'

export const ApiStatus = () => {
  if (typeof window !== 'undefined') {
    const currentHostname = window.location.hostname
    return StatusFromHostname(currentHostname)
  }
  return null
}

export const StatusFromHostname = (currentHostname: string) => {
  if (currentHostname.startsWith('localhost')) return 'latest'

  if (currentHostname.endsWith('contensis.cloud')) {
    if (currentHostname.indexOf('preview.') > -1) {
      return 'latest'
    } else {
      return 'published'
    }
  }

  if (currentHostname.endsWith('cloud.contensis.com')) {
    if (currentHostname.indexOf('preview-') > -1) {
      return 'latest'
    } else {
      return 'published'
    }
  }

  return 'published'
}

//

const contensisConfig = {
  rootUrl: 'https://cms-uni-hull.cloud.contensis.com',
  accessToken: 'rRdGBGOsFIIWzFDeXMz9Zf6gpSq8sidf5b2yeys4pqfDbQO7',
  projectId: 'website',
  versionStatus: ApiStatus() as VersionStatus
}

export const client = Client.create(contensisConfig)
