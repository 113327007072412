function PlayToPause(props: {
  fill: string
  secondaryfill: any
  title: string
}) {
  const fill = props.fill || 'currentColor'
  const secondaryfill = props.secondaryfill || fill
  const title = props.title || 'play to pause'
  const css = `.nc-int-play-to-pause{--transition-duration:0.4s}.nc-int-play-to-pause *{transition:transform var(--transition-duration) cubic-bezier(.77,0,.18,1),opacity var(--transition-duration)}.nc-int-play-to-pause :nth-child(1){transition-delay:.1s}.nc-int-play-to-pause :nth-child(2),.nc-int-play-to-pause :nth-child(3){transform-origin:50% 50%;transform:translateY(-32px)}.nc-int-play-to-pause :nth-child(3){transition-delay:.1s}.nc-int-play-to-pause.nc-int-icon-state-b :nth-child(1){transform:translateY(32px);transition-delay:0s}.nc-int-play-to-pause.nc-int-icon-state-b :nth-child(2),.nc-int-play-to-pause.nc-int-icon-state-b :nth-child(3){transform:translateY(0);opacity:1}.nc-int-play-to-pause.nc-int-icon-state-b :nth-child(2){transition-delay:.1s}.nc-int-play-to-pause.nc-int-icon-state-b :nth-child(3){transition-delay:0s}`

  function handleClick(e: {
    currentTarget: {
      querySelector: (arg0: string) => any
      dispatchEvent: (arg0: Event) => void
    }
  }) {
    const group = e.currentTarget.querySelector('.js-nc-int-icon')
    if (!group) return
    group.classList.toggle('nc-int-icon-state-b')
    e.currentTarget.dispatchEvent(new Event('ncstatechanged'))
  }

  return (
    <svg
      onClick={handleClick}
      height='32'
      width='32'
      viewBox='0 0 32 32'
      xmlns='http://www.w3.org/2000/svg'
    >
      <title>{title}</title>
      <g
        fill={secondaryfill}
        stroke={secondaryfill}
        strokeLinecap='square'
        strokeLinejoin='miter'
        strokeMiterlimit='10'
        strokeWidth='2'
      >
        <g className='js-nc-int-icon nc-int-play-to-pause nc-int-icon-state-b'>
          <polygon fill='none' points='5 29 27 16 5 3 5 29' stroke={fill} />
          <rect height='26' width='7' fill='none' stroke={fill} x='5' y='3' />
          <rect height='26' width='7' fill='none' x='20' y='3' />
        </g>
        <style>{css}</style>
      </g>
    </svg>
  )
}

export default PlayToPause
