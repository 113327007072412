import { cva } from 'cva'
import { twMerge } from 'tailwind-merge'

import ArrowRight from './Icon/arrow-right'

const button = cva('button', {
  variants: {
    background: {
      chartreuse: ['bg-char-20', 'text-blue-d-60', 'hover:bg-char'],
      'ink blue': ['bg-blue-d-20', 'text-white-60', 'hover:bg-blue-d'],
      'mid blue': ['bg-blue-m-20', 'text-white-60', 'hover:bg-blue-m'],
      'light blue': ['bg-blue-l-20', 'text-blue-d-60', 'hover:bg-blue-l'],
      green: ['bg-green-20', 'text-blue-d-60', 'hover:bg-green'],
      purple: ['bg-purple-20', 'text-blue-d-60', 'hover:bg-purple'],
      pink: ['bg-pink-20', 'text-blue-d-60', 'hover:bg-pink'],
      orange: ['bg-orange-20', 'text-blue-d-60', 'hover:bg-orange']
    }
  }
})

type Link = {
  info: {
    label: string | undefined
    entry: any
    externalLink: string | undefined
    ariaLabel: string | undefined
  }
  background: any
}
const Pill = ({ background, info }: Link) => {
  return (
    <a
      href={
        info.externalLink === null ? info?.entry?.sys.uri : info.externalLink
      }
      {...(info?.ariaLabel !== '' ? { 'aria-label': info?.ariaLabel } : {})}
      className={[
        twMerge(
          button({ background }),
          'flex items-center gap-4 rounded-full px-[30px] py-3 !text-tag font-medium leading-[1.5] text-blue-d no-underline transition-all sm:!text-base'
        )
      ].join(' ')}
    >
      {info.label} <ArrowRight fill={''} secondaryfill={undefined} title={''} />
    </a>
  )
}

export default Pill
