import sanitizeHtml from 'sanitize-html'

const Footnotes = ({ sectData }: any) => {
  const sanitizeMarkup = sanitizeHtml(sectData)

  return (
    <section aria-label='Footnotes'>
      <div
        className='relative mx-auto max-w-[1600px] px-[20px] py-[45px] text-xs sm:px-[50px] sm:py-[70px] lg:px-[20px] lg:py-[100px] [&_a]:text-xs [&_ol]:ml-4 [&_ol]:list-decimal [&_p]:text-xs [&_ul]:ml-4 [&_ul]:list-disc'
        dangerouslySetInnerHTML={{ __html: sanitizeMarkup }}
      />
    </section>
  )
}

export default Footnotes
