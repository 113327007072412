/* eslint-disable @typescript-eslint/no-explicit-any */
import { cva } from 'cva'
import { Key, useEffect, useRef } from 'react'
import { twMerge } from 'tailwind-merge'
import sanitizeHtml from 'sanitize-html'
import Button from '~/components/UI/Button/Button'
import ArrowRight from './Icon/arrow-right'
import { register } from 'swiper/element/bundle'
import { ClientOnly } from 'remix-utils/client-only'

const section = cva('section', {
  variants: {
    background: {
      white: ['bg-white', 'text-blue-d'],
      chartreuse: ['bg-char', 'text-blue-d'],
      'ink blue': ['bg-blue-d', 'text-white'],
      'mid blue': ['bg-blue-m', 'text-white'],
      'light blue': ['bg-blue-l', 'text-blue-d'],
      green: ['bg-green', 'text-blue-d'],
      purple: ['bg-purple', 'text-blue-d'],
      pink: ['bg-pink', 'text-blue-d'],
      orange: ['bg-orange', 'text-blue-d']
    }
  }
})

type Slides = {
  title: string | null | undefined
  description: string
  image: {
    asset: { sys: { uri: string | undefined } }
    altText: string | undefined
  }
  link: string | undefined
}

register()

const Slider = ({ sliderData }: any) => {
  const swiperElRef = useRef(null) as any
  const swiperImgRef = useRef(null) as any

  useEffect(() => {
    const swiperTest = swiperElRef.current

    const swiperParams = {
      slidesPerView: 1,
      spaceBetween: 20,
      speed: 200,
      effect: 'fade',
      fadeEffect: { crossFade: true },
      breakpoints: {
        1024: {
          spaceBetween: 50
        }
      },
      pagination: {
        el: '.research-pagination',
        clickable: true,
        bulletActiveClass: 'active',
        renderBullet: function () {
          return '<div class="swiper-pagination-bullet w-[15px] h-[15px] border-solid border-[2px] cursor-pointer border-blue-m [&.active]:cursor-default [&.active]:bg-blue-m"></div>'
        }
      }
    }

    Object.assign(swiperTest, swiperParams)

    swiperTest.initialize()
  }, [])

  return (
    <>
      <div className='relative z-[2] col-span-1 col-start-1 row-start-2 flex max-w-[calc(60vw-24px)] items-center overflow-visible pl-[20px] sm:row-start-2 sm:mb-[20px] sm:max-w-[50vw] sm:items-end sm:pl-[50px] xl:col-start-2 xl:row-start-3 xl:h-max xl:w-[calc(100%+110px)] xl:max-w-[40vw] xl:self-end xl:pl-0'>
        <swiper-container
          init='false'
          class='research-slider w-[calc(100%+3px)]'
          ref={swiperElRef}
          controller-control='.image-slider'
        >
          {sliderData?.nestedImageSlide.map(
            (slide: Slides, index: Key | null | undefined) => (
              <swiper-slide key={index}>
                <a
                  href={slide?.link}
                  className='mb-[20px] flex items-center justify-between no-underline sm:mb-[30px] sm:gap-[20px] xl:mb-[50px] xl:gap-[40px]'
                >
                  <div className=''>
                    <h3 className='text-sm text-blue-m no-underline hover:underline md:text-h4'>
                      {slide?.title}
                    </h3>
                    <p className='text-blue-d'>{slide?.description}</p>
                  </div>
                  <div className='hidden shrink-0 sm:block'>
                    <ArrowRight
                      fill={'#1D4CF2'}
                      secondaryfill={undefined}
                      title={''}
                    />
                  </div>
                </a>
              </swiper-slide>
            )
          )}
        </swiper-container>

        <div className='research-pagination absolute -bottom-[10px] z-[1] flex gap-[10px] sm:-bottom-[20px]'></div>
      </div>

      <div className='col-start-2 row-start-2 aspect-square max-w-[40vw] sm:max-w-[50vw] sm:self-end xl:col-start-3 xl:row-span-3 xl:row-start-1'>
        <swiper-container
          class='image-slider h-full'
          ref={swiperImgRef}
          slides-per-view='1'
          navigation='false'
          pagination='false'
          effect='fade'
          simulate-touch='false'
          allow-touch-move='false'
        >
          {sliderData?.nestedImageSlide.map(
            (slide: Slides, index: Key | null | undefined) => (
              <swiper-slide key={index}>
                <div className='flex h-full items-end justify-between gap-[100px]'>
                  <img
                    className='aspect-square h-full w-full object-cover'
                    src={
                      (process.env.NODE_ENV === 'development'
                        ? 'https://preview-uni-hull.cloud.contensis.com'
                        : '') +
                      slide?.image.asset.sys.uri +
                      '?f=webp&q=60'
                    }
                    alt={slide?.image.altText}
                    width={864}
                    height={864}
                  ></img>
                </div>
              </swiper-slide>
            )
          )}
        </swiper-container>
      </div>
    </>
  )
}

const Research = ({ sectData }: any) => {
  const bgOverlay =
    (process.env.NODE_ENV === 'development'
      ? 'https://preview-uni-hull.cloud.contensis.com'
      : '') +
    sectData?.backgroundOverlay.asset.sys.uri +
    '?f=webp&q=60'
  const featTextCol = sectData?.featuredTextColour.entryTitle.toLowerCase()

  return (
    <section className='relative' aria-label='Our research'>
      <div
        style={{ backgroundImage: `url(${bgOverlay})` }}
        className='absolute left-0 top-0 z-[2] h-[40px] min-w-[100vw] -translate-y-[20px] bg-cover bg-no-repeat opacity-40 sm:h-[70px] sm:-translate-y-[40px] xl:-translate-y-[30px]'
      ></div>

      <div className='relative mx-auto grid w-[100vw] max-w-[1920px] auto-cols-min auto-rows-min gap-x-[24px] gap-y-[45px] overflow-hidden pb-[45px] sm:mb-[70px] sm:gap-0 xl:grid-cols-[14%_30%_45%] xl:grid-rows-3 xl:gap-[100px]'>
        <h1
          className={[
            twMerge(section({ background: featTextCol })),
            'col-span-2 col-start-1 row-span-2 row-start-2 mb-0 hidden h-full max-h-[260px] max-w-[730px] origin-bottom-right -translate-x-[100%] rotate-90 self-end p-[50px] text-center xl:block'
          ].join(' ')}
          dangerouslySetInnerHTML={{
            __html: sanitizeHtml(sectData?.featuredText.substring(0, 50), {
              allowedTags: ['em']
            })
          }}
        ></h1>

        <div className='col-span-2 col-start-1 row-start-1 flex flex-col justify-end gap-[24px] px-[20px] pt-[65px] sm:row-start-1 sm:-mb-[30px] sm:gap-[36px] sm:pl-[50px] sm:pr-[150px] sm:pt-[100px] xl:col-span-1 xl:col-start-2 xl:row-span-2 xl:row-start-1 xl:mb-0 xl:mt-[100px] xl:gap-[70px] xl:p-0'>
          <h2 className='mb-0 text-blue-m'>{sectData?.description}</h2>

          <div className='flex w-max gap-md'>
            {sectData?.uiPrimaryButtons.map(
              (button: buttonType, index: Key | null | undefined) => (
                <Button
                  key={index}
                  btn={button as any}
                  colour={button?.colours.toLowerCase()}
                />
              )
            )}
          </div>
        </div>

        <ClientOnly>{() => <Slider sliderData={sectData} />}</ClientOnly>
      </div>
    </section>
  )
}

export default Research
