/* eslint-disable @typescript-eslint/no-explicit-any */
import { Key } from 'react'
import { cva } from 'cva'
import { twMerge } from 'tailwind-merge'
import sanitizeHtml from 'sanitize-html'
import Button from '../UI/Button/Button'

const section = cva('section', {
  variants: {
    background: {
      white: ['bg-white', 'text-blue-m'],
      chartreuse: ['bg-char', 'text-blue-d'],
      'ink blue': ['bg-blue-d', 'text-white'],
      'mid blue': ['bg-blue-m', 'text-white'],
      'light blue': ['bg-blue-l', 'text-blue-d'],
      green: ['bg-green', 'text-blue-d'],
      purple: ['bg-purple', 'text-blue-d'],
      pink: ['bg-pink', 'text-blue-d'],
      orange: ['bg-orange', 'text-blue-d']
    },
    text: {
      white: ['text-white'],
      'ink blue': ['text-blue-d'],
      'mid blue': ['text-blue-m']
    }
  }
})

const SpotLight = ({ background, sectData }: any) => {
  const bgImage =
    (process.env.NODE_ENV === 'development'
      ? 'https://preview-uni-hull.cloud.contensis.com'
      : '') +
    sectData?.backgroundImage.asset.sys.uri +
    '?f=webp&q=40'

  const textColour = sectData?.textColour.entryTitle.toLowerCase()

  return (
    <section
      className={[
        twMerge(section({ background })),
        'max-w-screen block-bottom relative overflow-x-clip after:bg-white'
      ].join(' ')}
      aria-label='Spotlight statement'
    >
      <div
        style={{ backgroundImage: `url(${bgImage})` }}
        className='absolute -left-0 top-1/2 block aspect-square h-[60%] -translate-x-1/2 -translate-y-1/2 bg-contain bg-center bg-no-repeat opacity-10 sm:h-[75%]'
      ></div>
      <div
        style={{ backgroundImage: `url(${bgImage})` }}
        className='absolute -right-0 top-1/2 hidden aspect-square h-[75%] -translate-y-1/2 translate-x-1/2 bg-contain bg-center bg-no-repeat opacity-10 lg:block'
      ></div>

      <div className='container flex-col gap-[70px]'>
        <div className='mx-auto flex max-w-[1200px] flex-col items-center justify-center gap-[36px] px-[20px] py-[20px] sm:gap-[64px] sm:py-[30px] lg:py-[70px]'>
          <div
            className={[
              twMerge(section({ text: textColour })),
              'text-center font-serif text-h2 font-medium sm:text-h1'
            ].join(' ')}
            dangerouslySetInnerHTML={{
              __html: sanitizeHtml(sectData?.text, { allowedTags: ['em'] })
            }}
          ></div>
          <div className='flex justify-center gap-md'>
            {sectData?.uiPrimaryButtons.map(
              (button: buttonType, index: Key | null | undefined) => (
                <Button
                  key={index}
                  btn={button as any}
                  colour={button?.colours.toLowerCase()}
                />
              )
            )}
          </div>
        </div>
      </div>
    </section>
  )
}

export default SpotLight
