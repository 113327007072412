/* eslint-disable @typescript-eslint/no-explicit-any */
import { cva } from 'cva'
import { CSSProperties } from 'react'
import { twMerge } from 'tailwind-merge'

import { format } from 'date-fns'

import { useQuery } from '@tanstack/react-query'
import { AllNews, Events } from '~/lib/newsQuery/newsConfig'

import ArrowRight from '../UI/Pill/Icon/arrow-right'

const section = cva('section', {
  variants: {
    background: {
      white: ['bg-white-40', 'text-blue-d'],
      chartreuse: ['bg-char-40', 'text-blue-d'],
      'ink blue': ['bg-blue-d-40', 'text-white'],
      'mid blue': ['bg-blue-m-40', 'text-white'],
      'light blue': ['bg-blue-l-40', 'text-blue-d'],
      green: ['bg-green-40', 'text-blue-d'],
      purple: ['bg-purple-40', 'text-blue-d'],
      pink: ['bg-pink-40', 'text-blue-d'],
      orange: ['bg-orange-40', 'text-blue-d']
    },
    afterBg: {
      white: ['after:bg-blue-d'],
      chartreuse: ['after:bg-char'],
      'ink blue': ['after:bg-blue-d'],
      'mid blue': ['after:bg-blue-m'],
      'light blue': ['after:bg-blue-l'],
      green: ['after:bg-green'],
      purple: ['after:bg-purple'],
      pink: ['after:bg-pink'],
      orange: ['after:bg-orange']
    },
    line: {
      white: ['bg-blue-d'],
      chartreuse: ['bg-char'],
      'ink blue': ['bg-blue-d'],
      'mid blue': ['bg-blue-m'],
      'light blue': ['bg-blue-l'],
      green: ['bg-green'],
      purple: ['bg-purple'],
      pink: ['bg-pink'],
      orange: ['bg-orange']
    },
    textColour: {
      white: ['text-blue-d'],
      chartreuse: ['text-char'],
      'ink blue': ['text-blue-d'],
      'mid blue': ['text-blue-m'],
      'light blue': ['text-blue-l'],
      green: ['text-green'],
      purple: ['text-purple'],
      pink: ['text-pink'],
      orange: ['text-orange']
    }
  }
})

interface MyCustomCSS extends CSSProperties {
  '--cols': number
  '--gap': string
}

const NewsEvents = ({
  background,
  textColour,
  afterBg,
  line,
  sectData
}: any) => {
  const { error, data, isSuccess } = useQuery({
    queryKey: ['AllNews'],
    queryFn: () => AllNews()
  })
  const {
    error: err,
    data: eventData,
    isSuccess: success
  } = useQuery({
    queryKey: ['Events'],
    queryFn: () => Events()
  })

  return (
    <section
      className={[twMerge(section({ background }))].join(' ')}
      aria-label='News & Events'
    >
      <div
        style={{ '--cols': 2, '--gap': '210px' } as MyCustomCSS}
        className={[
          'container flex flex-col gap-[45px] sm:gap-[70px] lg:flex-row lg:gap-[var(--gap)]',
          twMerge(section({ afterBg })),
          'after:left-auto after:z-auto after:order-2 after:h-[5px] after:w-[100%] sm:after:h-[10px] lg:after:absolute lg:after:left-1/2 lg:after:h-[calc(100%-200px)] lg:after:w-[10px] lg:after:-translate-x-1/2'
        ].join(' ')}
      >
        <div className='order-1 grid auto-rows-min grid-cols-[auto_min-content] gap-xs sm:grid-cols-[1fr_2fr_min-content] sm:gap-x-sm sm:gap-y-md lg:basis-[calc((100%/var(--cols))-(var(--gap)/var(--cols)*(var(--cols)-1)))]'>
          <h2
            className={[
              twMerge(section({ textColour })),
              'col-span-2 row-start-1 mb-0 min-w-max sm:col-span-1 sm:text-h2'
            ].join(' ')}
          >
            {sectData?.newsTitle}
          </h2>
          <span
            className={[
              twMerge([section({ line })]),
              'row-start-3 flex h-[3px] grow-[1] self-center sm:col-start-2 sm:row-start-1'
            ].join(' ')}
          ></span>

          {(sectData?.newsExternalLink !== null ||
            sectData?.newsLink !== null) && (
            <a
              href={
                sectData?.newsExternalLink === null
                  ? sectData?.newsLink?.sys.uri
                  : sectData?.newsExternalLink
              }
              className={[
                twMerge(section({ textColour })),
                'col-start-2 row-start-3 flex items-center justify-between gap-1 underline hover:no-underline sm:col-start-3 sm:row-start-1 sm:min-w-max'
              ].join(' ')}
            >
              See all{' '}
              <ArrowRight fill={''} secondaryfill={undefined} title={''} />
            </a>
          )}
          {/* </div> */}
          <ul className='col-span-2 row-start-2 mb-[10px] flex flex-col gap-[30px] sm:col-span-3 sm:gap-[50px]'>
            {error && <li>{'An error has occurred: ' + error.message}</li>}

            {isSuccess &&
              data?.map((item: any, index: Index) => (
                <li className='flex flex-col' key={index}>
                  <p className='tag mb-[8px] lg:mb-[20px]'>
                    {format(item.date, 'dd MMM yyyy')}
                  </p>
                  <a
                    href={item.sys.uri !== null ? item.sys.uri : item.pageUrl}
                    className={[
                      twMerge(section({ textColour })),
                      'no-underline hover:underline'
                    ].join(' ')}
                  >
                    <h3 className='mb-0 text-h4'>
                      {item?.seoStandardFields?.title}
                    </h3>
                  </a>
                </li>
              ))}
          </ul>
        </div>
        <div className='order-3 grid auto-rows-min grid-cols-[auto_min-content] gap-xs sm:grid-cols-[1fr_1fr_min-content] sm:gap-x-sm sm:gap-y-md lg:basis-[calc((100%/var(--cols))-(var(--gap)/var(--cols)*(var(--cols)-1)))]'>
          {/* <div
            className={[
              twMerge(section({ textColour })),
              'flex flex-col items-start gap-sm sm:flex-row sm:items-center sm:justify-between sm:gap-md'
            ].join(' ')}
          > */}
          <h2
            className={[
              twMerge(section({ textColour })),
              'col-span-2 row-start-1 mb-0 min-w-max sm:col-span-1 sm:text-h2'
            ].join(' ')}
          >
            {sectData?.eventsTitle}
          </h2>
          <span
            className={[
              twMerge(section({ line })),
              'row-start-3 flex h-[3px] grow-[1] self-center sm:col-start-2 sm:row-start-1'
            ].join(' ')}
          ></span>

          {(sectData?.eventsExternalLink !== null ||
            sectData?.eventsLink !== null) && (
            <a
              href={
                sectData?.eventsExternalLink === null
                  ? sectData?.eventsLink?.sys.uri
                  : sectData?.eventsExternalLink
              }
              className={[
                twMerge(section({ textColour })),
                'col-start-2 row-start-3 flex items-center justify-between gap-1 underline sm:col-start-3 sm:row-start-1 sm:min-w-max'
              ].join(' ')}
            >
              See all{' '}
              <ArrowRight fill={''} secondaryfill={undefined} title={''} />
            </a>
          )}
          {/* </div> */}
          {/* <ul className='col-span-2 row-start-2 mb-[10px] flex flex-col gap-[30px] sm:col-span-3 sm:gap-[50px]'>
            {sectData?.nestedFeedItem.map((item: any, index: Index) => (
              <li className='flex flex-col' key={index}>
                <p className='tag mb-[8px] lg:mb-[20px]'>
                  {format(item.date, 'dd MMM yyyy')}
                </p>
                <a
                  href={
                    item?.externalLink === null
                      ? item?.link?.sys.uri
                      : item?.externalLink
                  }
                  className={[
                    twMerge(section({ textColour })),
                    'no-underline hover:underline'
                  ].join(' ')}
                  title={item?.title}
                >
                  <h3 className='mb-0 text-h4'>{item?.title}</h3>
                </a>
              </li>
            ))}
          </ul> */}
          <ul className='col-span-2 row-start-2 mb-[10px] flex flex-col gap-[30px] sm:col-span-3 sm:gap-[50px]'>
            {err && <li>{'An error has occurred: ' + err.message}</li>}

            {success &&
              eventData?.map((item: any, index: Index) => (
                <li className='flex flex-col' key={index}>
                  <p className='tag mb-[8px] lg:mb-[20px]'>
                    {format(item.dateTime?.from, 'dd MMM yyyy')}
                  </p>
                  <a
                    href={item.link}
                    className={[
                      twMerge(section({ textColour })),
                      'no-underline hover:underline'
                    ].join(' ')}
                  >
                    <h3 className='mb-0 text-h4'>{item?.entryTitle}</h3>
                  </a>
                </li>
              ))}
          </ul>
        </div>
      </div>
    </section>
  )
}

export default NewsEvents
