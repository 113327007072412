/* eslint-disable @typescript-eslint/no-explicit-any */
import type { LoaderFunctionArgs, MetaFunction } from '@remix-run/node'
import { json } from '@remix-run/node'
import { dehydrate, QueryClient, useQuery } from '@tanstack/react-query'
import { ClientOnly } from 'remix-utils/client-only'

import { Homepage } from '~/lib/contensis/pageQuery'

import Hero from '~/components/Hero/Hero'
import LatestResearch from '~/components/LatestResearch/LatestResearch'
import CourseSearch from '~/components/CourseSearch/CourseSearch'
import SpotLight from '~/components/SpotLight/SpotLight'
import Achievements from '~/components/Achievements/Achievements'
import Research from '~/components/Research/Research'
import NewsEvents from '~/components/NewsEvents/NewsEvents'

import { Navigation, Footer } from '@university-of-hull/component-library'
import { AllNews, Events, ResNews } from '~/lib/newsQuery/newsConfig'
import Footnotes from '~/components/Footnotes/Footnotes'

export async function loader({ request }: LoaderFunctionArgs) {
  const queryClient = new QueryClient()
  const url = new URL(request.url)

  await queryClient.prefetchQuery({
    queryKey: ['homeData'],
    queryFn: () => Homepage(url.pathname)
  })

  await queryClient.prefetchQuery({
    queryKey: ['ResearchNews'],
    queryFn: () => ResNews()
  })

  await queryClient.prefetchQuery({
    queryKey: ['AllNews'],
    queryFn: () => AllNews()
  })

  await queryClient.prefetchQuery({
    queryKey: ['Events'],
    queryFn: () => Events()
  })

  return json({ dehydratedState: dehydrate(queryClient) })
}

export const meta: MetaFunction<typeof loader> = ({ data }) => {
  const meta = data?.dehydratedState?.queries[0]?.state?.data as {
    sys: {
      id: string
      uri: string
      allUris: string[]
    }
    seoStandardFields: {
      title: string
      description: string
      socialImage: {
        asset: {
          sys: {
            uri: string
          }
        }
      }
    }
  }

  return [
    meta?.sys?.allUris.length > 1 &&
      ({
        rel: 'canonical',
        href: `${meta.sys?.uri}`
      } as any),
    { title: `${meta?.seoStandardFields?.title} | University of Hull` },
    { name: 'description', content: `${meta?.seoStandardFields?.description}` },
    { name: 'IDL:EntryId', content: `${meta?.sys?.id}` },
    { name: 'IDL:ProjectId', content: 'website' },
    { property: 'og:title', content: `${meta?.seoStandardFields?.title}` },
    { property: 'og:url', content: `https://www.hull.ac.uk${meta?.sys.uri}` },
    { property: 'og:type', content: 'website' },
    {
      property: 'og:description',
      content: `${meta?.seoStandardFields?.description}`
    },
    {
      property: 'og:image',
      content: `https://www.hull.ac.uk${meta?.seoStandardFields?.socialImage?.asset?.sys?.uri}`
    },
    { name: 'twitter:card', content: 'summary_large_image' },
    { name: 'twitter:site', content: '@UniOfHull' },
    { name: 'twitter:title', content: `${meta?.seoStandardFields?.title}` },
    {
      name: 'twitter:description',
      content: `${meta?.seoStandardFields?.description}`
    },
    {
      name: 'twitter:image',
      content: `https://www.hull.ac.uk${meta?.seoStandardFields?.socialImage?.asset?.sys?.uri}`
    }
  ]
}

export default function Index() {
  const { data } = useQuery({
    queryKey: ['homeData'],
    queryFn: () => Homepage('/')
  })

  return (
    <>
      {data && <Navigation />}
      {data?.heroFeature && <Hero sectData={data?.heroFeature} />}

      <main id='main-content' className='min-h-screen'>
        {data?.feedsLatestResearch && (
          <LatestResearch
            sectData={data?.feedsLatestResearch}
            background={data?.feedsLatestResearch.backgroundColour?.entryTitle.toLowerCase()}
            colour={data?.feedsLatestResearch.textColour?.entryTitle.toLowerCase()}
            line={data?.feedsLatestResearch.textColour?.entryTitle.toLowerCase()}
          />
        )}

        {data?.searchCourseSearch && (
          <>
            <CourseSearch
              sectData={data?.searchCourseSearch}
              background={data?.searchCourseSearch.backgroundColour?.entryTitle.toLowerCase()}
            />
            <section className='block-bottom relative h-[20px] w-full bg-blue-l after:bg-blue-l sm:h-[30px]'></section>
          </>
        )}

        {data?.featureSpotlight && (
          <SpotLight
            sectData={data?.featureSpotlight}
            background={data?.featureSpotlight.backgroundColour?.entryTitle.toLowerCase()}
          />
        )}

        {data?.listAchievements && (
          <ClientOnly>
            {() => (
              <Achievements
                sectData={data?.listAchievements}
                background={data?.listAchievements.backgroundColour.entryTitle.toLowerCase()}
              />
            )}
          </ClientOnly>
        )}

        {data?.sliderResearchFeature && (
          <Research sectData={data?.sliderResearchFeature} />
        )}

        {data?.feedsNewsAndEvents && (
          <NewsEvents
            sectData={data?.feedsNewsAndEvents}
            background={data?.feedsNewsAndEvents.backgroundColour.entryTitle.toLowerCase()}
            afterBg={data?.feedsNewsAndEvents.backgroundColour.entryTitle.toLowerCase()}
            line={data?.feedsNewsAndEvents.textColour.entryTitle.toLowerCase()}
            textColour={data?.feedsNewsAndEvents.textColour.entryTitle.toLowerCase()}
          />
        )}

        {data?.footnotes && <Footnotes sectData={data?.footnotes} />}
      </main>
      {data && <Footer />}
    </>
  )
}
