/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import sanitizeHtml from 'sanitize-html'
import { motion } from 'framer-motion'

import { cva } from 'cva'
import { twMerge } from 'tailwind-merge'

import { AllCourses, UGCourses, PGTCourses, PGRCourses } from './courseConfig'

import { useDebounceCallback } from 'usehooks-ts'

import Button from '~/components/UI/Button/Button'
import Pill from '~/components/UI/Pill/Pill'

import { Entry } from 'contensis-delivery-api/lib/models'
import { PagedList } from 'contensis-core-api/lib'

import zoom from '~/assets/icon-library/standard/zoom.svg'

const section = cva('section', {
  variants: {
    background: {
      white: ['bg-white', 'text-blue-d'],
      chartreuse: ['bg-char', 'text-blue-d'],
      'ink blue': ['bg-blue-d', 'text-white'],
      'mid blue': ['bg-blue-m', 'text-white'],
      'light blue': ['bg-blue-l', 'text-blue-d'],
      green: ['bg-green', 'text-blue-d'],
      purple: ['bg-purple', 'text-blue-d'],
      pink: ['bg-pink', 'text-blue-d'],
      orange: ['bg-orange', 'text-blue-d']
    }
  }
})

const CourseSearch = ({ background, sectData }: any) => {
  const [search, setSearch] = useState('')
  const debounced = useDebounceCallback(setSearch, 500)

  let dataSource: Promise<PagedList<Entry> | undefined> | null = null

  switch (sectData?.dataSource[0].toLowerCase()) {
    case 'all courses':
      dataSource = AllCourses(search)
      break
    case 'ug courses':
      dataSource = UGCourses(search)
      break
    case 'pgt courses':
      dataSource = PGTCourses(search)
      break
    case 'pgr courses':
      dataSource = PGRCourses(search)
      break

    default:
      dataSource = AllCourses(search)
      break
  }

  const { error, data, isFetched, isSuccess, refetch } = useQuery({
    queryKey: ['courses', search],
    queryFn: () => dataSource
  })

  const handleChange = (e: any) => {
    debounced(e?.target?.value)
    refetch()
  }

  const container = {
    hidden: { opacity: 0, height: 0 },
    show: {
      opacity: 1,
      height: 'auto',
      transition: {
        staggerChildren: 0.3
      }
    }
  }

  const listItem = {
    hidden: { opacity: 0 },
    show: { opacity: 1 }
  }

  return (
    <section
      className={[
        twMerge(section({ background })),
        'block-bottom after:bg-blue-d'
      ].join(' ')}
      aria-label='Course search'
    >
      <div className='container flex-col gap-[50px] pt-[65px] text-white sm:pt-[115px] lg:pt-[150px]'>
        <div className='sm:[gap-30px] flex flex-col gap-[20px] lg:gap-[50px]'>
          <h2 className=''>{sectData?.searchTitle}</h2>
          <div className='flex flex-wrap gap-[30px]'>
            <input
              name='courseSearch'
              aria-label='Search for a courses'
              style={{
                backgroundImage: `url(${zoom})`
              }}
              className='min-w-[320px] grow-[1] border border-black bg-blue-l-20 bg-[length:30px_auto] bg-[10px_center] bg-no-repeat py-3 pl-12 text-blue-d placeholder:text-blue-d-80'
              onChange={handleChange}
              type='search'
              placeholder={sectData?.searchPlaceholder}
            />
            <Button btn={sectData?.searchButton as any} colour='pink' />
          </div>
          {error && <p>{'An error has occurred: ' + error.message}</p>}

          {isFetched && isSuccess && search !== '' && (
            <motion.ul
              variants={container}
              initial='hidden'
              animate='show'
              className='mt-3 max-h-96 overflow-auto'
            >
              {data &&
                search !== '' &&
                data?.items?.map((item: any) => (
                  <motion.li
                    variants={listItem}
                    key={item?.sys?.id}
                    className='border-b-white/8 max-w-[86ch] border-b py-10'
                  >
                    {item?.studyLevel?.entryTitle && (
                      <p className='tag text-white'>
                        {item?.studyLevel?.entryTitle}
                      </p>
                    )}
                    <a
                      href={item?.pageUrl}
                      className='block text-white no-underline hover:underline'
                    >
                      <h3 className='mb-7'>
                        <strong>{item?.seoStandardFields?.title}</strong>
                      </h3>
                    </a>
                    {item?.seoStandardFields?.websiteDescription && (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: sanitizeHtml(
                            item?.seoStandardFields?.websiteDescription
                          )
                        }}
                      />
                    )}
                  </motion.li>
                ))}

              {(data?.items.length as any) <= 0 && (
                <li className='max-w-[86ch] border-b border-b-white py-5'>
                  Your query <strong>{'"' + search + '"'}</strong> returned 0
                  results, please try a different search term
                </li>
              )}
            </motion.ul>
          )}
        </div>

        <div className='flex flex-col gap-[20px] lg:gap-[50px]'>
          <h3 className='text-h4 lg:text-h2'>{sectData?.secondaryTitle}</h3>
          <div className='flex flex-wrap gap-5 lg:gap-8'>
            {sectData?.pillButtons.map(
              (
                button: {
                  label: string
                  ariaLabel: string
                  externalLink: string
                  entry: any
                  colours: any
                },
                index: Index
              ) => (
                <Pill
                  key={index}
                  info={button}
                  background={button?.colours.entryTitle.toLowerCase()}
                />
              )
            )}
          </div>
        </div>
      </div>
    </section>
  )
}

export default CourseSearch
