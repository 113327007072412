import { Op, OrderBy, Query } from 'contensis-delivery-api'
import { client } from '../contensis/client'

const fields = ['seoStandardFields.title', 'sys.uri', 'pageUrl', 'tags', 'date']
const eventFields = ['entryTitle', 'link', 'dateTime']

// ALL NEWS QUERY
export const AllNews = async () => {
  const query = new Query(
    Op.equalTo('sys.versionStatus', 'published'),
    Op.equalTo('seoStandardFields.includeInSearch', 'true'),
    Op.equalTo('sys.contentTypeId', 'newsArticles'),
    Op.and(Op.not(Op.equalTo('tags[]', '0/1/380/1039')))
  )

  query.pageSize = 3
  query.fields = fields
  query.orderBy = OrderBy.desc('date')

  try {
    const result = await client.entries.search(query)
    return result.items
  } catch (err) {
    console.log(err)
  }
}
// RESEARCH NEWS QUERY
export const ResNews = async () => {
  const query = new Query(
    Op.equalTo('sys.versionStatus', 'published'),
    Op.equalTo('seoStandardFields.includeInSearch', 'true'),
    Op.equalTo('sys.contentTypeId', 'newsArticles'),
    Op.and(Op.equalTo('tags[]', '0/1/380/503'))
  )

  query.pageSize = 3
  query.fields = fields
  query.orderBy = OrderBy.desc('date')

  try {
    const result = await client.entries.search(query)
    return result.items
  } catch (err) {
    console.log(err)
  }
}
// EVENTS QUERY
export const Events = async () => {
  const now = new Date()

  const query = new Query(
    Op.equalTo('sys.versionStatus', 'published'),
    Op.equalTo('sys.contentTypeId', 'eventsStandard'),
    Op.greaterThanOrEqualTo('dateTime.from', now)
  )

  query.pageSize = 3
  query.fields = eventFields
  query.orderBy = OrderBy.asc('dateTime.from')

  try {
    const result = await client.entries.search(query)
    return result.items
  } catch (err) {
    console.log(err)
  }
}
