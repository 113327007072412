/* eslint-disable @typescript-eslint/no-explicit-any */
import { useRef } from 'react'

import PlayToPause from './icon/play-to-pause'
import { TitleAnim } from './TitleAnim'

const Hero = ({ sectData }: any) => {
  const vidRef = useRef<HTMLVideoElement>(null)

  const videoPause = () => {
    if (vidRef.current?.paused) {
      return vidRef.current?.play()
    }

    return vidRef.current?.pause()
  }

  return (
    <header className='relative flex min-h-screen items-center overflow-hidden'>
      <div className='absolute -z-[1] h-full w-full object-cover'>
        <div className='absolute h-full w-full bg-black/30 object-cover'></div>
        {sectData?.imageDesktop && sectData?.video === null && (
          <picture>
            <source
              media='(max-width: 578px)'
              srcSet={
                (process.env.NODE_ENV === 'development'
                  ? 'https://preview-uni-hull.cloud.contensis.com'
                  : '') +
                sectData?.imageMobile?.asset.sys.uri +
                '?f=webp&q=40'
              }
              type='image/webp'
            />
            <source
              srcSet={
                (process.env.NODE_ENV === 'development'
                  ? 'https://preview-uni-hull.cloud.contensis.com'
                  : '') +
                sectData?.imageDesktop?.asset.sys.uri +
                '?f=webp&q=40'
              }
              type='image/webp'
            />
            <img
              className='h-full w-full object-cover'
              loading='eager'
              width={1440}
              height={810}
              src={
                (process.env.NODE_ENV === 'development'
                  ? 'https://preview-uni-hull.cloud.contensis.com'
                  : '') +
                sectData?.imageDesktop?.asset.sys.uri +
                '?f=webp&q=40'
              }
              alt={sectData?.imageDesktop?.altText}
            />
          </picture>
        )}
        {sectData?.video && (
          <video
            ref={vidRef}
            className='aspect-video h-full w-full object-cover'
            preload='metadata'
            muted
            autoPlay
            loop
            playsInline
            poster={
              (process.env.NODE_ENV === 'development'
                ? 'https://preview-uni-hull.cloud.contensis.com'
                : '') +
              sectData?.imageDesktop?.asset.sys.uri +
              '?f=webp&q=40'
            }
            width={1440}
            height={810}
          >
            <source
              src={
                (process.env.NODE_ENV === 'development'
                  ? 'https://preview-uni-hull.cloud.contensis.com'
                  : '') + sectData?.video?.sys.uri
              }
              type='video/mp4'
            />
            <p className='sr-only'>{sectData?.videoDescription}</p>
          </video>
        )}
      </div>

      {sectData?.video && (
        <button
          type='button'
          className='absolute bottom-16 right-5 z-[3] flex aspect-square w-auto scale-105 items-center justify-center lg:bottom-28 lg:right-8'
          onClick={videoPause}
        >
          <PlayToPause fill={'white'} secondaryfill={undefined} title={''} />
        </button>
      )}

      <div className='mx-auto flex min-h-[800px] flex-col items-center justify-center py-32 text-white sm:py-32'>
        <TitleAnim data={sectData} />
      </div>
    </header>
  )
}

export default Hero
