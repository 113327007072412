import { Op, Query } from 'contensis-delivery-api'
import { client } from '../../lib/contensis/client'

const standard = (value: string) =>
  Op.or(
    Op.equalTo('entryTitle', value).weight(10),
    Op.startsWith('entryTitle', value).weight(9),
    Op.contains('entryTitle', value).weight(8),
    Op.freeText('entryTitle', value, true).weight(3),
    Op.contains('searchContent', value).weight(6),
    Op.contains('seoStandardFields.keywords', value).weight(3),
    Op.or(
      Op.contains('seoStandardFields.keywords', value).weight(2),
      Op.freeText('seoStandardFields.description', value, true).weight(2),
      Op.freeText('seoStandardFields.websiteDescription', value, true).weight(2)
    )
  )

const fields = [
  'entryTitle',
  'studyLevel.entryTitle',
  'seoStandardFields.title',
  'seoStandardFields.description',
  'seoStandardFields.websiteDescription',
  'seoStandardFields.keywords',
  'seoStandardFields.focusKeyphrase',
  'seoStandardFields.includeInSearch',
  'sys.uri',
  'pageUrl'
]

// ALL COURSES SEARCH QUERY
export const AllCourses = async (value: string) => {
  const query = new Query(
    Op.equalTo('sys.versionStatus', 'published'),
    Op.equalTo('seoStandardFields.includeInSearch', 'true'),
    standard(value),
    Op.or(
      Op.equalTo('sys.contentTypeId', 'coursesUndergraduate'),
      Op.equalTo('sys.contentTypeId', 'coursesPostgraduateTaught'),
      Op.equalTo('sys.contentTypeId', 'pgrCourses'),
      Op.equalTo('sys.contentTypeId', 'cpdCourses'),
      Op.equalTo('sys.contentTypeId', 'apprenticeships')
    )
  )

  query.pageSize = 400
  query.fields = fields

  try {
    const result = await client.entries.search(query)
    return result
  } catch (err) {
    console.log(err)
  }
}

// UG COURSE SEARCH QUERY
export const UGCourses = async (value: string) => {
  const query = new Query(
    Op.equalTo('sys.versionStatus', 'published'),
    Op.equalTo('seoStandardFields.includeInSearch', 'true'),
    standard(value),
    Op.or(Op.equalTo('sys.contentTypeId', 'coursesUndergraduate'))
  )

  query.pageSize = 400
  query.fields = fields

  try {
    const result = await client.entries.search(query)
    return result
  } catch (err) {
    console.log(err)
  }
}

// PGT COURSE SEARCH QUERY
export const PGTCourses = async (value: string) => {
  const query = new Query(
    Op.equalTo('sys.versionStatus', 'published'),
    Op.equalTo('seoStandardFields.includeInSearch', 'true'),
    standard(value),
    Op.or(Op.equalTo('sys.contentTypeId', 'coursesPostgraduateTaught'))
  )

  query.pageSize = 400
  query.fields = fields

  try {
    const result = await client.entries.search(query)
    return result
  } catch (err) {
    console.log(err)
  }
}

// PGR COURSE SEARCH QUERY
export const PGRCourses = async (value: string) => {
  const query = new Query(
    Op.equalTo('sys.versionStatus', 'published'),
    Op.equalTo('seoStandardFields.includeInSearch', 'true'),
    standard(value),
    Op.or(Op.equalTo('sys.contentTypeId', 'pgrCourses'))
  )

  query.pageSize = 400
  query.fields = fields

  try {
    const result = await client.entries.search(query)
    return result
  } catch (err) {
    console.log(err)
  }
}
