/* eslint-disable @typescript-eslint/no-explicit-any */
import { cva } from 'cva'
import { CSSProperties } from 'react'
import { twMerge } from 'tailwind-merge'

import { format } from 'date-fns'

import { useQuery } from '@tanstack/react-query'
import { ResNews } from '~/lib/newsQuery/newsConfig'

import ArrowRight from '../UI/Pill/Icon/arrow-right'

const section = cva('section', {
  variants: {
    background: {
      white: ['bg-white', 'text-blue-d'],
      chartreuse: ['bg-char', 'text-blue-d'],
      'ink blue': ['bg-blue-d', 'text-white'],
      'mid blue': ['bg-blue-m', 'text-white'],
      'light blue': ['bg-blue-l', 'text-blue-d'],
      green: ['bg-green', 'text-blue-d'],
      purple: ['bg-purple', 'text-blue-d'],
      pink: ['bg-pink', 'text-blue-d'],
      orange: ['bg-orange', 'text-blue-d']
    },
    colour: {
      white: ['text-white'],
      chartreuse: ['text-char'],
      'ink blue': ['text-blue-d'],
      'mid blue': ['text-blue-m'],
      'light blue': ['text-blue-l'],
      green: ['text-green'],
      purple: ['text-purple'],
      pink: ['text-pink'],
      orange: ['text-orange']
    },
    line: {
      white: ['bg-white'],
      chartreuse: ['bg-char'],
      'ink blue': ['bg-blue-d'],
      'mid blue': ['bg-blue-m'],
      'light blue': ['bg-blue-l'],
      green: ['bg-green'],
      purple: ['bg-purple'],
      pink: ['bg-pink'],
      orange: ['bg-orange']
    },
    border: {
      white: ['border-white'],
      chartreuse: ['border-char'],
      'ink blue': ['border-blue-d'],
      'mid blue': ['border-blue-m'],
      'light blue': ['border-blue-l'],
      green: ['border-green'],
      purple: ['border-purple'],
      pink: ['border-pink'],
      orange: ['border-orange']
    }
  }
})

interface MyCustomCSS extends CSSProperties {
  '--cols': number
  '--gap': string
}

const LatestResearch = ({ background, colour, line, sectData }: any) => {
  const bgOverlay =
    (process.env.NODE_ENV === 'development'
      ? 'https://preview-uni-hull.cloud.contensis.com'
      : '') +
    sectData?.backgroundOverlay.asset.sys.uri +
    '?f=webp&q=60'
  const bgImage =
    (process.env.NODE_ENV === 'development'
      ? 'https://preview-uni-hull.cloud.contensis.com'
      : '') +
    sectData?.backgroundImage.asset.sys.uri +
    '?f=webp&q=60'

  const secTitle = sectData?.title.split(' ')

  const { error, data, isSuccess } = useQuery({
    queryKey: ['ResearchNews'],
    queryFn: () => ResNews()
  })

  return (
    <section
      className='relative z-[2] -mb-[20px] -mt-[40px] sm:-mb-[50px]'
      aria-label={'Our ' + secTitle[0] + ' ' + secTitle[1]}
    >
      <div
        style={{ backgroundImage: `url(${bgOverlay})` }}
        className='absolute left-0 h-[80px] min-w-[100vw] -translate-y-[20px] bg-cover bg-no-repeat opacity-50 sm:h-[calc(100%/1.5)] sm:-translate-y-[50px]'
      ></div>
      <div
        style={{ backgroundImage: `url(${bgImage})` }}
        className='absolute bottom-[20px] left-0 z-[-1] h-[calc(100%-40px)] min-w-[100vw] bg-cover bg-no-repeat md:bottom-[50px] md:h-[calc(100%-90px)]'
      ></div>

      <div className='relative mx-auto flex max-w-[1600px] flex-col pl-5 lg:px-5'>
        <div
          className={[
            twMerge(section({ background }), section({ colour })),
            'relative grid max-w-[1340px] grid-cols-[1fr_auto] gap-xs self-end p-[20px] sm:grid-cols-[1fr_2fr_auto] sm:gap-sm sm:p-[50px] sm:pt-[30px] lg:gap-md lg:p-[70px] lg:pt-[50px]',
            'text-blue-m before:absolute before:-left-[100vw] before:-top-[75px] before:hidden before:h-[75px] before:w-[100vw] before:bg-char lg:before:block'
          ].join(' ')}
        >
          <h2 className='col-span-2 row-start-1 mb-0 min-w-max font-serif text-h4 font-medium sm:col-span-1 sm:text-dec-50'>
            {secTitle[0]} <em>{secTitle[1]}</em>
          </h2>
          <span
            className={[
              section({ line }),
              'row-start-3 flex h-[3px] grow flex-row items-center gap-xs self-center bg-blue-d sm:col-start-2 sm:row-start-1'
            ].join(' ')}
          ></span>
          {(sectData?.externalLink !== null || sectData?.link !== null) && (
            <a
              href={sectData?.externalLink}
              aria-label={'See more of our ' + secTitle[1]}
              className='col-start-2 row-start-3 flex min-w-max items-center justify-end gap-1 self-center underline hover:no-underline sm:col-start-3 sm:row-start-1'
            >
              More research
              <ArrowRight fill={''} secondaryfill={undefined} title={''} />
            </a>
          )}

          <ul
            style={{ '--cols': 3, '--gap': '50px' } as MyCustomCSS}
            className='col-span-2 row-start-2 flex flex-col gap-sm sm:col-span-3 sm:flex-row md:gap-[var(--gap)]'
          >
            {error && <li>{'An error has occurred: ' + error.message}</li>}
            {isSuccess &&
              data?.map((item: any, index: Index) => (
                <li
                  key={index}
                  className={[
                    twMerge(section({ border: 'ink blue' })),
                    'basis-full border-b-[1px] border-solid pb-[20px] last:border-none sm:border-b-0 sm:border-r-[1px] sm:pb-0 sm:pr-[30px] md:basis-[calc((100%/var(--cols))-(var(--gap)/var(--cols)*(var(--cols)-1)))]'
                  ].join(' ')}
                >
                  <p className='tag text-blue-d'>
                    {format(item.date, 'dd MMM yyyy')}
                  </p>
                  <a
                    href={item.sys.uri !== null ? item.sys.uri : item.pageUrl}
                    className='no-underline hover:underline'
                  >
                    <h3 className='mb-0 text-h5'>
                      {item?.seoStandardFields?.title}
                    </h3>
                  </a>
                </li>
              ))}
          </ul>
        </div>
      </div>
    </section>
  )
}

export default LatestResearch
