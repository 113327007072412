function ArrowRight(props: {
  fill: string
  secondaryfill: any
  title: string
}) {
  const fill = props.fill || 'currentColor'
  const secondaryfill = props.secondaryfill || fill
  const title = props.title || 'arrow right'

  return (
    <svg
      height='80'
      width='80'
      viewBox='0 0 64 64'
      xmlns='http://www.w3.org/2000/svg'
      className='h-[50px] w-[50px] md:h-[80px] md:w-[80px]'
    >
      <title>{title}</title>
      <g
        fill={secondaryfill}
        stroke={secondaryfill}
        strokeLinecap='square'
        strokeLinejoin='miter'
        strokeMiterlimit='10'
        strokeWidth='4'
      >
        <polyline fill='none' points='43,14 61,32 43,50 ' stroke='#ffffff' />
        <line
          fill='none'
          stroke={fill}
          strokeLinecap='butt'
          x1='-2'
          y1='32'
          x2='56'
          y2='32'
        />
        <polyline fill='none' points='38,14 56,32 38,50  ' stroke={fill} />
      </g>
    </svg>
  )
}

export default ArrowRight
