function ArrowRight(props: { fill: string; secondaryfill: any; title: string }) {
  const fill = props.fill || 'currentColor'
  const secondaryfill = props.secondaryfill || fill
  const title = props.title || 'arrow right'

  return (
    <svg height='20' width='20' viewBox='0 0 48 48' xmlns='http://www.w3.org/2000/svg'>
      <title>{title}</title>
      <g
        fill={secondaryfill}
        stroke={secondaryfill}
        strokeLinecap='square'
        strokeLinejoin='miter'
        strokeMiterlimit='10'
        strokeWidth='4'>
        <line fill='none' stroke={fill} strokeLinecap='butt' x1='2' x2='45' y1='24' y2='24' />
        <polyline fill='none' points='31,10 45,24 31,38 ' stroke={fill} />
      </g>
    </svg>
  )
}

export default ArrowRight
