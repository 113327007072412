/* eslint-disable @typescript-eslint/no-explicit-any */
import { cva } from 'cva'
import { Key, useEffect, useRef } from 'react'
import { twMerge } from 'tailwind-merge'
import sanitizeHtml from 'sanitize-html'
import { register } from 'swiper/element/bundle'
import { useDebounceValue, useWindowSize } from 'usehooks-ts'

const section = cva('section', {
  variants: {
    background: {
      white: ['bg-white', 'text-blue-d'],
      chartreuse: ['bg-char', 'text-blue-d'],
      'ink blue': ['bg-blue-d', 'text-white'],
      'mid blue': ['bg-blue-m', 'text-white'],
      'light blue': ['bg-blue-l', 'text-blue-d'],
      green: ['bg-green', 'text-blue-d'],
      purple: ['bg-purple', 'text-blue-d'],
      pink: ['bg-pink', 'text-blue-d'],
      orange: ['bg-orange', 'text-blue-d']
    },
    border: {
      white: ['border-white'],
      chartreuse: ['border-char'],
      'ink blue': ['border-blue-d'],
      'mid blue': ['border-blue-m'],
      'light blue': ['border-blue-l'],
      green: ['border-green'],
      purple: ['border-purple'],
      pink: ['border-pink'],
      orange: ['border-orange']
    }
  }
})

interface Props {
  children: React.ReactNode
  colour?: any
}

type Items = {
  title: string | null | undefined
  description: string
  colour: any
  icon: {
    asset: { sys: { uri: string | undefined } }
    altText: string | undefined
  }
}

register()

const Achievements = ({ background, sectData }: any) => {
  const swiperElRef = useRef(null) as any
  const size = useWindowSize()
  const [debouncedSize] = useDebounceValue(size.width, 500)

  useEffect(() => {
    const swiperTest = swiperElRef.current

    const swiperParams = {
      slidesPerView: 1.2,
      spaceBetween: 20,
      grid: {
        rows: 2
      },
      breakpoints: {
        640: {
          slidesPerView: 1.4,
          spaceBetween: 50
        }
      },
      pagination: {
        el: '.achievments-pagination',
        clickable: true,
        bulletActiveClass: 'active',
        renderBullet: function () {
          return '<div class="swiper-pagination-bullet w-[15px] h-[15px] border-solid border-[2px] cursor-pointer border-white [&.active]:cursor-default [&.active]:bg-white"></div>'
        }
      }
    }

    if (debouncedSize < 1024 && swiperTest && !swiperTest.initialized) {
      Object.assign(swiperTest, swiperParams)
      swiperTest.initialize()
    }
  }, [Wrapper])

  function Wrapper({ children }: Props) {
    if (debouncedSize < 1024) {
      return (
        <swiper-container
          init='false'
          class='flex h-[450px] max-w-[100%] flex-col flex-wrap pb-[20px] sm:h-[550px] sm:pb-[40px]'
          ref={swiperElRef}
        >
          {children}
        </swiper-container>
      )
    } else {
      return <ul className='flex flex-row flex-wrap gap-[50px]'>{children}</ul>
    }
  }

  function Items({ children, colour }: Props) {
    if (debouncedSize < 1024) {
      return (
        <swiper-slide
          class={[
            twMerge(section({ border: colour?.entryTitle.toLowerCase() })),
            'flex !h-[calc(50%-40px)] flex-row items-center gap-[20px] border-y-0 border-b-[3px] border-t-0 border-solid pb-[20px] sm:!h-[calc(50%-55px)] sm:border-b-[5px]'
          ].join(' ')}
        >
          {children}
        </swiper-slide>
      )
    } else {
      return (
        <li
          className={[
            twMerge(section({ border: colour?.entryTitle.toLowerCase() })),
            'flex basis-[calc(33.33%-34px)] flex-row items-center gap-[30px] border-y-0 border-b-[10px] border-t-0 border-solid pb-[30px]'
          ].join(' ')}
        >
          {children}
        </li>
      )
    }
  }

  return (
    <section
      className={[
        twMerge(section({ background })),
        'block-top before:bg-blue-d'
      ].join(' ')}
      aria-label='Our achievements'
    >
      <div className='container flex-col gap-[20px] lg:gap-[70px]'>
        <h2 className=''>{sectData?.title}</h2>
        <Wrapper>
          {sectData?.nestedIconCard.map(
            (item: Items, index: Key | null | undefined) => (
              <Items key={index} colour={item.colour}>
                <img
                  className='w-full min-w-0 max-w-[75px] lg:max-w-[150px]'
                  src={
                    (process.env.NODE_ENV === 'development'
                      ? 'https://preview-uni-hull.cloud.contensis.com'
                      : '') + item?.icon.asset.sys.uri
                  }
                  alt={item?.icon.altText}
                  width={150}
                  height={150}
                ></img>
                <div className='flex flex-col gap-[15px] md:items-start lg:gap-[20px]'>
                  <h3 className='mb-0 font-serif text-h3 font-medium italic sm:text-dec-48'>
                    {item?.title}
                  </h3>
                  <p
                    className='text-[15px] text-tag uppercase sm:text-[17px]'
                    dangerouslySetInnerHTML={{
                      __html: sanitizeHtml(
                        item?.description.substring(0, 100),
                        {
                          allowedTags: ['strong', 'em', 'sup']
                        }
                      )
                    }}
                  ></p>
                </div>
              </Items>
            )
          )}
        </Wrapper>

        <div className='achievments-pagination absolute bottom-[50px] z-[1] flex gap-[10px] sm:bottom-[80px] lg:hidden'></div>
      </div>
    </section>
  )
}

export default Achievements
